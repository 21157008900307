import { CONSTANTS } from '../api';

let initState = {
    isLoading: false,
    coordinate: {
        lat: 1.5594,
        lng: 103.6386,
    },
    center: {
        lat: 1.6603,
        lng: 103.6380
    },
    energy: 40.0,
    speed: 6.3,
    data: [],
    basestation: [],
    graph: null,
    message: '',
    notification: [],
    listErrSystem: [],
    errorSystem: { id: 0, show: false, types: '', value: '', resolve: false, datetime: '' }
}

export function dashboard(state = initState, action) {
    //console.log(action)
    let _data = []
    // let averageLat = 0
    // let averageLng = 0
    let __state = { ...state }
    switch (action.type) {

        case CONSTANTS.DASHBOARD.END_LOADING:
            __state = {...state}
            __state.isLoading = false
            return __state

        case CONSTANTS.DASHBOARD.START_LOADING:
            __state = {...state}
            __state.isLoading = true
            return __state

        case CONSTANTS.DASHBOARD.SUCCESS:
            return {
                data: action.result,
                graph: [...state.graph]
            };

        case CONSTANTS.DASHBOARD.FAILURE:
            return {
                message: action.message,
                data: [...state.data],
                graph: [...state.graph]
            };

        case CONSTANTS.DASHBOARD.GRAPH_SUCCESS:
            return {
                ...state,
                graph: action.result
            };

        case CONSTANTS.DASHBOARD.GRAPH_FAILURE:
            return {
                message: action.message,
                graph: [...state.graph]
            };

        case CONSTANTS.DASHBOARD.NOTIFICATION:
            return { ...state, notification: action.data };

        case CONSTANTS.DASHBOARD.ERRORSYSTEM:
            // console.log(action.data)
            __state.listErrSystem.push(action.data)
            __state.errorSystem = {
                id: __state.listErrSystem[0].id,
                show: true,
                types: __state.listErrSystem[0].types,
                value: __state.listErrSystem[0].value,
                datetime: __state.listErrSystem[0].datetime,
                resolve: __state.listErrSystem[0].resolve
            }

            return __state

        case CONSTANTS.DASHBOARD.ERRORSYSTEM_RESET:
            __state.listErrSystem.shift()
            if (__state.listErrSystem.length === 0) {
                __state.errorSystem = initState.errorSystem
            } else {
                __state.errorSystem = {
                    id: __state.listErrSystem[0].id,
                    show: true,
                    types: __state.listErrSystem[0].types,
                    value: __state.listErrSystem[0].value,
                    datetime: __state.listErrSystem[0].datetime,
                    resolve: __state.listErrSystem[0].resolve
                }
            }
            return __state

        case CONSTANTS.DASHBOARD.FIND_LATEST_BASESTATION:
            state.basestation = []

            for (let i = 0; i < action.result.length; i++) {
                if(action.result[i].deviceData.length !== 0) {
                    state.basestation.push(action.result[i])
                }
            }

            return state

        case CONSTANTS.DASHBOARD.FIND_LATEST_BASESTATION_MANAGER:
            state.basestation = []

            // console.log(action.result)

            for (let i = 0; i < action.result.length; i++) {
                if (action.result[i].deviceData.length !== 0 && action.result[i].deviceData[0].collarDevice.basestation.appUserId === action.result.uid) {
                    state.basestation.push(action.result[i])
                }
            }

            // for (let i = 0; i < action.result.length; i++) {
            //     if (action.result[i].deviceData[0].collarDevice === undefined) {
            //         if (action.result[i] !== null && action.result[i].deviceData[0].collarDevice.basestation.appUserId === action.result.uid) {
            //             state.basestation.push(action.result[i])
            //         }
            //     }
            // }

            console.log(state.basestation)

            return state

        case CONSTANTS.DASHBOARD.LATEST_DATA_SUCCESS_MANAGER:
            __state = {...state}

            _data = []
            
            console.log(action.result)

            for (let i = 0; i < action.result.length; i++) {
                if(action.result[i] !== null && action.result[i].deviceData.length !== 0) {
                    console.log(action.result[i].deviceData[0].collarDevice.basestation.appUserId)
                    if(action.result[i].deviceData[0].collarDevice.basestation.appUserId === action.result.uid) {
                        _data.push(action.result[i])
                    }
                }
            }

            // for (let i = 0; i < action.result.length; i++) {
            //     if(!(Object.keys(action.result[i]).length === 0 && action.result[i].constructor === Object)) {
            //         if (action.result[i] !== null && action.result[i].deviceData[0].collarDevice.appUserId === action.result.uid) {
            //             _data.push(action.result[i])
            //         }
            //     }
            // }

            if (_data.length !== 0) {
                __state.center.lat = _data.reduce(function (sum, value) {
                    return sum + value.gps_latitude_tx
                }, 0) / _data.length;

                __state.center.lng = _data.reduce(function (sum, value) {
                    return sum + value.gps_longitude_tx
                }, 0) / _data.length;
            }
            else {
                __state.center = state.center
            }

            __state.isLoading = false

            __state.data = _data

            return __state
            
        case CONSTANTS.DASHBOARD.LIST_DEVICE_SUCCESS:
            __state = {...state}

            __state.data = action.result

            return __state


        case CONSTANTS.DASHBOARD.COORDINATE_SUCCESS:
            __state = { ...state }
            // __state.coordinate = action.result
            // __state.energy = action.result.energy
            // __state.speed = action.result.speed

            __state.isLoading = false

            for (let a=0; a<__state.data.length; a++) {
                if (__state.data[a] !== null && __state.data[a].id_tx === action.result.data.id_tx) {
                    __state.data[a].gps_latitude_tx = action.result.data.gps_latitude_tx
                    __state.data[a].gps_longitude_tx = action.result.data.gps_longitude_tx
                    __state.data[a].gps_altitude_tx = action.result.data.gps_altitude_tx
                    __state.data[a].gps_latitude_rx = action.result.data.gps_latitude_rx
                    __state.data[a].gps_longitude_rx = action.result.data.gps_longitude_rx
                    __state.data[a].gps_altituder_rx = action.result.data.gps_altituder_rx
                    // console.log(__state.data[a])
                }
            }

            // console.log('Latest Marker Data: \n', __state)
            // console.log('MQTT: \n',action.result)
            return __state

        case CONSTANTS.DASHBOARD.LATEST_DATA_SUCCESS:
            __state = {...state}

            _data = []

            for (let i = 0; i < action.result.length; i++) {
                if(action.result[i] !== null && action.result[i].deviceData.length !== 0) {
                    _data.push(action.result[i])
                }
            }
            
            // for (let i = 0; i < action.result.length; i++) {
            //     if (action.result[i] !== null) {
            //         _data.push(action.result[i])
            //     }
            // }

            if (_data.length !== 0) {
                __state.center.lat = _data.reduce(function (sum, value) {
                    return sum + value.gps_latitude_tx
                }, 0) / _data.length;

                __state.center.lng = _data.reduce(function (sum, value) {
                    return sum + value.gps_longitude_tx
                }, 0) / _data.length;
            }

            __state.isLoading = false

            __state.data = _data

            return __state

        case CONSTANTS.CLEAR:
            return initState
        default:
            return state
    }
}
