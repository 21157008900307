import { CONSTANTS } from '../api';
let initState = {
    isLoading: false,
    center: {
        lat: 1.6603,
        lng: 103.6380
    },
    data: [],
    dataList: [],
    pagination: {
      itemPerPage: 10,
      currentPage: 1,
      totalPage: 1
    },
    searchValue: '',
    dataSearch: [],
}

export function devicedata(state = initState, action) {
    let averageLat = 0
    let averageLng = 0
    let __state = {}
    let _j = 0
    let _data = []
    let count = 0

    switch (action.type) {
        case CONSTANTS.DEVICEDATA.DEVICEDATA_STARTLOADING:
            __state = {}
            __state = {
                ...state,
                isLoading: true
            }

            return __state

        case CONSTANTS.DEVICEDATA.DEVICEDATA_ENDLOADING:
            __state = {}
            __state = {
                ...state,
                isLoading: false
            }

            return __state

        case CONSTANTS.DEVICEDATA.FIND_SUCCESS_MANAGER:
            console.log(action.result.data)
            _data = []
            let totalData = []
            count = action.result.currIndex * 10

            for (let i = 0; i < action.result.data.length; i++) {
                action.result.data[i]['count'] = count+1
                count++
            }
            
            state = {
                ...state,
                data: action.result.data,
                dataList: action.result.data,
                pagination: {
                    itemPerPage: 10,
                    currentPage: action.result.currIndex+1,
                    totalPage: action.result.totalPage
                },
                error: null
            };
            
            return state;

        case CONSTANTS.DEVICEDATA.FILTER_SUCCESS_MANAGER:
            __state = {...state}
            _data = []
            totalData = []
            _j = 0

            for (let i = 0; i < action.result.length; i++) {
                if ( (action.result[i].deviceData[0].collarDevice.appUserId === action.result.uid) ) {
                    totalData.push(action.result[i])
                }
            }

            for (let i = 0; i < totalData.length; i++) {
                totalData[i]['count'] = i+1
                if (i < 10) {
                  _data.push(totalData[i])
                }
            }

            averageLat = _data.reduce(function (sum, value) {
                if(value !== null){
                    return sum + value.gps_latitude_tx
                }
                else return ''
            }, 0) / _data.length;

            averageLng = _data.reduce(function (sum, value) {
                if(value !== null){
                    return sum + value.gps_longitude_tx
                }
                else return ''
            }, 0) / _data.length;
            
            __state = {
                data: totalData,
                dataList: _data,
                center: {
                    lat: averageLat,
                    lng: averageLng
                },
                pagination: {
                  itemPerPage: 10,
                  currentPage: 1,
                  totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };

            console.log(__state)

            return __state;

        case CONSTANTS.DEVICEDATA.FILTER_SUCCESS:
            __state = {...state}
            _data = []
            _j = 0

            console.log(action.result)

            for (let i = 0; i < action.result.length; i++) {
                action.result[i]['count'] = i+1
                if (i < 10) {
                  _data.push(action.result[i])
                }
            }

            averageLat = _data.reduce(function (sum, value) {
                if(value !== null){
                    return sum + value.gps_latitude_tx
                }
                else return ''
            }, 0) / _data.length;

            averageLng = _data.reduce(function (sum, value) {
                if(value !== null){
                    return sum + value.gps_longitude_tx
                }
                else return ''
            }, 0) / _data.length;
            
            __state = {
                data: action.result,
                dataList: _data,
                center: {
                    lat: averageLat,
                    lng: averageLng
                },
                pagination: {
                  itemPerPage: 10,
                  currentPage: 1,
                  totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };

            return __state;


        case CONSTANTS.DEVICEDATA.FIND_SUCCESS:

            // let _data1 = []
            count = action.result.currIndex * 10

            for (let i = 0; i < action.result.data.length; i++) {
                action.result.data[i]['count'] = count+1
                count++
            }

            // console.log(action.result)

            state = {
                ...state,
                data: action.result.data,
                dataList: action.result.data,
                pagination: {
                    itemPerPage: 10,
                    currentPage: action.result.currIndex+1,
                    totalPage: action.result.totalPage
                },
                error: null
            };
            
            return state;

        case CONSTANTS.HISTORY.LOGS_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.currentPage = action.result
    
            __state.dataList = []
            _j = 0
            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
    
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.data[i])
                _j++
            }
            }

            console.log('LOG PAGE CHANGE => ',  __state.dataList)

            state = {
                ...__state
            };

            return state;

        case CONSTANTS.DEVICEDATA.HISTORY_SEARCH:
            __state = { ...state }
            __state.dataList = []
            __state.dataSearch = []
    
            __state.pagination.currentPage = 1
            __state.searchValue = action.result
            
            state.data.forEach(element => {
                if ( element.id.toString().includes(action.result) || element.collarDevice.device_id.includes(action.result)) {
                  __state.dataSearch.push(element)
                }
            });

            __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)
  
            _j = 0
            for (let i = 0; i < __state.dataSearch.length; i++) {
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.dataSearch[i])
                _j++
            }
            }

            state = {...__state}

            return state

        case CONSTANTS.CLEAR:
            return initState

        default:
            return state
    }
}
