import { CONSTANTS } from '../api'

let initState = {
    isLoading: false,
    center: {
        lat: 1.6603,
        lng: 103.6380
    },
    zoom: 17,
    data: []
}

export function mapcsv (state = initState, action) {
    let __state = {}
    let averageLat = 0
    let averageLng = 0

    switch (action.type) {
        case CONSTANTS.MAPCSV.MAPCSV_ROWCLICK:
            __state = {}
            __state = {
                ...state,
                center: action.result.center
            }
            console.log(action.result.center)

            return __state

        case CONSTANTS.MAPCSV.MAPCSV_CLEAR:
            state = {
                isLoading: false,
                center: {
                    lat: 1.6603,
                    lng: 103.6380
                },
                data: []
            }

            return state

        case CONSTANTS.MAPCSV.MAPCSV_STARTLOADING:
            __state = {}
            __state = {
                ...state,
                isLoading: true
            }

            return __state

        case CONSTANTS.MAPCSV.MAPCSV_ENDLOADING:
            __state = {}
            __state = {
                ...state,
                isLoading: false
            }

            return __state

        case CONSTANTS.MAPCSV.MAPCSV_FIND_SUCCESS:
            __state = {}

            averageLat = action.result.reduce(function (sum, value) {
                if(value !== null){
                    return sum + value.gps_latitude_tx
                }
                else return ''
            }, 0) / action.result.length;

            averageLng = action.result.reduce(function (sum, value) {
                if(value !== null){
                    return sum + value.gps_longitude_tx
                }
                else return ''
            }, 0) / action.result.length;

            __state = {
                ...state,
                center: {
                    lat: averageLat,
                    lng: averageLng
                },
                data: action.result
            }

            return __state

        default:
            return state
    }
}