import { CONSTANTS } from '../api';

let initState = {
    data: [],
    dataList: [],
    pagination: {
      itemPerPage: 10,
      currentPage: 1,
      totalPage: 1
    },
    searchValue: '',
    dataSearch: [],
}

export function company (state = initState, action) {
    let __state = {}
    let __dataList = []
    let _j = 0
    let _data = []

    switch (action.type) {
        case CONSTANTS.COMPANY.COMPANY_SEARCH:
            __state = { ...state }
            __state.dataList = []
            __state.dataSearch = []
    
            __state.pagination.currentPage = 1
            __state.searchValue = action.result

            console.log(state.data[0])

            if(__state.searchValue.length !== 0) {
                state.data.forEach(element => {
                    if ( 
                        element.id.toString().toLowerCase().includes(action.result.toLowerCase()) || 
                        element.companyName.toLowerCase().includes(action.result.toLowerCase()) || 
                        element.description.toLowerCase().includes(action.result.toLowerCase()) ) {
                        __state.dataSearch.push(element)
                    }
                })

                __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)

                _j = 0

                for (let i = 0; i < __state.dataSearch.length; i++) {
                    if (_j < state.pagination.itemPerPage) {
                        __state.dataList.push(__state.dataSearch[i])
                        _j++
                    }
                }

                state = {...__state}
            }
            else {
                // console.log(state)
                _data = []

                for (let i = 0; i < action.result.length; i++) {
                    action.result[i]['count'] = i
                    if (i < 5) {
                        _data.push(action.result[i])
                    }
                }
            }

            return state

        case CONSTANTS.COMPANY.FIND_ALL_COMPANY:
            __state = {}
            __dataList = []

            for (let i = 0; i < action.result.length; i++) {
                action.result[i]['count'] = i
                
                if (i < 10) {
                    __dataList.push(action.result[i])
                }
            }

            __state = {
                ...state,
                data: action.result,
                dataList: __dataList,
                pagination: {
                    itemPerPage: 10,
                    currentPage: 1,
                    totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            }
            return __state

        case CONSTANTS.COMPANY.LOGS_PAGE_CHANGE_COMPANY:
            __state = { ...state }
            __state.pagination.currentPage = action.result
    
            __state.dataList = []
            _j = 0
            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
    
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.data[i])
                _j++
            }
            }

            console.log('LOG PAGE CHANGE => ',  __state.dataList)

            state = {
                ...__state
            };

            return state;

        default:
            return state
    }
}