import { CONSTANTS } from '../api';

let initState = {
    data: [],
    dataList: [],
    pagination: {
      itemPerPage: 5,
      currentPage: 1,
      totalPage: 1
    },
    searchValue: '',
    dataSearch: [],
}

export function user (state = initState, action) {
    let __state = {}
    let _j = 0
    switch(action.type) {
        case CONSTANTS.USER.LIST_ALL_COMPANY_WITH_BASESTATION:
            __state = { ...state }
            __state.data = action.result

            return __state

        case CONSTANTS.USER.LIST_SUCCESS:
            let _dataList = []

            for (let i = 0; i < action.result.length; i++) {
                action.result[i]['count'] = i+1
                if (i < 5) {
                    _dataList.push(action.result[i])
                }
            }

            state = {
                ...state,
                data: action.result,
                dataList: _dataList,
                pagination: {
                    itemPerPage: 5,
                    currentPage: 1,
                    totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };
            
            return state;
        
        case CONSTANTS.USER.REGISTER_REQUEST:
            return {
                request: true,
                data: [...state.data]
            };
        
        case CONSTANTS.USER.REGISTER_SUCCESS:
            if (state.data.length > 0) {
                action.result.number = state.data[state.data.length - 1].number++
            }
            else {
                action.result.number = 1
                //state.data.push({action.result})
            }
        
            return {
                data: [action.result, ...state.data]
            };

        case CONSTANTS.HISTORY.LOGS_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.currentPage = action.result
    
            __state.dataList = []
            _j = 0
            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
    
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.data[i])
                _j++
            }
            }

            state = {
                ...__state
              };
              return state;

        case CONSTANTS.USER.USERS_SEARCH:
            __state = { ...state }
            __state.dataList = []
            __state.dataSearch = []
    
            __state.pagination.currentPage = 1
            __state.searchValue = action.result
            
            state.data.forEach(element => {
                if (element.name.toLowerCase().includes(action.result.toLowerCase()) || element.email.toLowerCase().includes(action.result.toLowerCase()) || element.appUser.companyName.toLowerCase().includes(action.result.toLowerCase())) {
                  __state.dataSearch.push(element)
                }
            });

            __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)
  
            _j = 0
            for (let i = 0; i < __state.dataSearch.length; i++) {
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.dataSearch[i])
                _j++
            }
            }

            state = {...__state}

            return state

        case CONSTANTS.AUTHORIZATION.LOGOUT_SUCCESS:
            return {}

        case CONSTANTS.CLEAR:
            return initState

        default: return state
    }
}