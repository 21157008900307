import { combineReducers } from 'redux';
import { dashboard } from './dashboard'
import { user } from './userdata';
import { devicedata } from './devicedata';
import { device } from './device';
import { historybydevice } from './history';
import { basestation } from './basestation';
import { mapcsv } from './mapcsv';
import { company } from './company';

const rootReducer = combineReducers({
  dashboard,
  user,
  devicedata,
  device,
  historybydevice,
  basestation,
  mapcsv,
  company
});

export default rootReducer;