import { CONSTANTS } from '../api';

let initState = {
    data: [],
    dataList: [],
    pagination: {
      itemPerPage: 10,
      currentPage: 1,
      totalPage: 1
    },
    allBasestation: false,
    searchValue: '',
    dataSearch: [],
}

export function basestation (state = initState, action) {
    // let __count = 0
    let __state = {}
    let __dataList = []
    let _j = 0

    switch(action.type) {
        case CONSTANTS.BASESTATION.FIND_ALL_SUCCESS_MANAGER:
            // __count = 0
            __state = {}
            __dataList = []
            let filterCompanyManager = []

            for (let i = 0; i < action.result.length; i++) {
                if( action.result[i].appUserId === action.result.uid ){
                    filterCompanyManager.push(action.result[i])
                }
            }

            for (let i = 0; i < filterCompanyManager.length; i++) {
                filterCompanyManager[i]['count'] = i
                
                if (i < 10) {
                    __dataList.push(filterCompanyManager[i])
                }
            }

            __state = {
                ...state,
                data: filterCompanyManager,
                dataList: __dataList,
                pagination: {
                    itemPerPage: 10,
                    currentPage: 1,
                    totalPage: Math.ceil(filterCompanyManager.length / state.pagination.itemPerPage)
                },
                error: null
            };
            
            return __state;

        case CONSTANTS.BASESTATION.FIND_ALL_SUCCESS:
            // __count = 0
            __state = {}
            __dataList = []

            for (let i = 0; i < action.result.length; i++) {
                action.result[i]['count'] = i
                
                if (i < 10) {
                    __dataList.push(action.result[i])
                }
            }

            const visible = action.result.reduce((total, device) => {
                if (device.visible === true) {
                    if (total + 1 === action.result.length) {
                        return true
                    }
                    else {
                        return total + 1
                    }
                }
                return total
            },0)

            if (visible === true) {
                state = {
                    ...state,
                    allBasestation: true
                }
            }
            else {
                state = {
                    ...state,
                    allBasestation: false
                }
            }

            __state = {
                ...state,
                data: action.result,
                dataList: __dataList,
                pagination: {
                    itemPerPage: 10,
                    currentPage: 1,
                    totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };
            
            return __state;

        case CONSTANTS.BASESTATION.BASESTATION_SEARCH:
            __state = { ...state }
            __state.dataList = []
            __state.dataSearch = []
    
            __state.pagination.currentPage = 1
            __state.searchValue = action.result

            // console.log('ID Action',state.data)
            state.data.forEach(element => {
                if ( element.id.toString().toLowerCase().includes(action.result.toLowerCase()) || element.basestation_id.toLowerCase().includes(action.result.toLowerCase()) || element.description.toLowerCase().includes(action.result.toLowerCase()) || element.company.companyName.toLowerCase().includes(action.result.toLowerCase()) ) {
                    __state.dataSearch.push(element)
                }
            });

            __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)
    
            _j = 0
            for (let i = 0; i < __state.dataSearch.length; i++) {
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.dataSearch[i])
                _j++
            }
            }

            return {...__state}

        case CONSTANTS.BASESTATION.FIND_LATEST_BASESTATION:
            __state = {}
            __state = { ...state }

            console.log(action.result[0].collarDevice.basestationId)
            
            const filteredBasestation = action.result.reduce((acc, current) => {
                const x = acc.find(item => item.collarDevice.basestationId === current.collarDevice.basestationId);
                if (!x) {
                return acc.concat([current]);
                } else {
                return acc;
                }
            }, []);

            __state.data = filteredBasestation

            return __state
        
        case CONSTANTS.BASESTATION.LOGS_PAGE_CHANGE_BASESTATION:
            __state = { ...state }
            __state.pagination.currentPage = action.result
    
            __state.dataList = []
            _j = 0
            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
    
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.data[i])
                _j++
            }
            }

            console.log('LOG PAGE CHANGE => ',  __state.dataList)

            state = {
                ...__state
            };

            return state;

        default:
            return state
    }
}