// View Page
const DASHBOARD = {
    START_LOADING: 'DASHBOARD_START_LOADING',
    END_LOADING: 'DASHBOARD_END_LOADING',
    REQUEST: 'DASHBOARD_REQUEST',
    SUCCESS: 'DASHBOARD_SUCCESS',
    FAILURE: 'DASHBOARD_FAILURE',
    COORDINATE_SUCCESS: 'COORDINATE_SUCCESS',
    GRAPH_SUCCESS: 'DASHBOARD_GRAPH_SUCCESS',
    GRAPH_FAILURE: 'DASHBOARD_GRAPH_FAILURE',
    NOTIFICATION: 'DASHBOARD_NOTIFICATION',
    ERRORSYSTEM: 'DASHBOARD_ERRORSYSTEM',
    ERRORSYSTEM_RESET: 'DASHBOARD_ERRORSYSTEM_RESET',
    LIST_DEVICE_SUCCESS: 'GET_DEVICE_LIST',
    LATEST_DATA_SUCCESS: 'LATEST_DATA_SUCCESS',
    LATEST_DATA_SUCCESS_MANAGER: 'LATEST_DATA_SUCCESS_MANAGER',
    FIND_LATEST_BASESTATION: 'ADMIN_DASHBOARD_FIND_LATEST_BASESTATION',
    FIND_LATEST_BASESTATION_MANAGER: 'MANAGER_DASHBOARD_FIND_LATEST_BASESTATION_MANAGER'
}

const AUTHORIZATION = {
    LOGIN_REQUEST: 'AUTHORIZATION_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTHORIZATION_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTHORIZATION_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'AUTHORIZATION_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTHORIZATION_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTHORIZATION_LOGOUT_FAILURE',
}

const USER = {
    // SEARCH: 'USERS_SEARCH',
    LIST_REQUEST: 'USERS_LIST_REQUEST',
    LIST_SUCCESS: 'USERS_LIST_SUCCESS',
    LIST_FAILURE: 'USERS_LIST_FAILURE',
    LIST_ALL_COMPANY_WITH_BASESTATION: 'USER_LIST_ALL_COMPANY_WITH_BASESTATION',

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    FIND_REQUEST: 'USERS_FIND_REQUEST',
    FIND_SUCCESS: 'USERS_FIND_SUCCESS',
    FIND_FAILURE: 'USERS_FIND_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
    
    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    USERS_COUNT_CHANGE: 'USERS_COUNT_CHANGE',
    USERS_PAGE_CHANGE: 'USERS_PAGE_CHANGE',

    USERS_SEARCH: 'USERS_SEARCH'
}
const DEVICEDATA = {
    DEVICEDATA_STARTLOADING: 'DEVICEDATA_STARTLOADING',
    DEVICEDATA_ENDLOADING: 'DEVICEDATA_ENDLOADING',
    FILTER_SUCCESS_MANAGER: 'FILTER_SUCCESS_MANAGER',
    FIND_SUCCESS_MANAGER: 'FIND_SUCCESS_MANAGER',
    FILTER_SUCCESS: 'DEVICEDATA_FILTER_SUCCESS',
    FIND_SUCCESS: 'FIND_SUCCESS',
    DATA: 'DATA',
    LATEST_READING: 'DEVICEDATA_LATEST_READING',
    LAST_READING: 'DEVICEDATA_LAST_READING',
    AVERAGE_READING: 'DEVICEDATA_AVERAGE_READING',
    AVERAGE_ERROR: 'DEVICEDATA_AVERAGE_ERROR',
    TOTAL_ERROR: 'DEVICEDATA_TOTAL_ERROR',
    HISTORY_SEARCH: 'HISTORY_SEARCH',
    FIND_FAILURE: 'DEVICEDATA_FIND_FAILURE'
}
const HISTORY = {
    CLEAR: 'HISTORY_CLEAR',
    FILTER_SUCCESS: 'HISTORY_FILTER_SUCCESS',
    FIND_SUCCESS: 'HISTORY_FIND_SUCCESS',
    FIND_FAILURE: 'HISTORY_FIND_FAILURE',
    // UPDATE_SUCCESS: 'HISTORY_UPDATE_SUCCESS',
    // UPDATE_FAILURE: 'HISTORY_UPDATE_FAILURE',
    LOGS_COUNT_CHANGE: 'LOGS_COUNT_CHANGE',
    LOGS_PAGE_CHANGE: 'LOGS_PAGE_CHANGE',
    LIST_BY_DEVICE_SUCCESS: 'LIST_BY_DEVICE_SUCCESS',
}

const COMPANY = {
    FIND_ALL_COMPANY: 'FIND_ALL_COMPANY',
    LOGS_PAGE_CHANGE_COMPANY: 'LOGS_PAGE_CHANGE_COMPANY',
    COMPANY_SEARCH: 'COMPANY_SEARCH'
}

const BASESTATION = {
    BASESTATION_SEARCH: 'SEARCH_BASESTATION',
    FIND_ALL_SUCCESS: 'FIND_ALL_BASESTATION',
    FIND_LATEST_BASESTATION: 'FIND_LATEST_BASESTATION',
    FIND_ALL_SUCCESS_MANAGER: "FIND_ALL_SUCCESS_MANAGER_BASESTATION",
    LOGS_PAGE_CHANGE_BASESTATION: "LOGS_PAGE_CHANGE_BASESTATION"
}

const DEVICE = {
    LOGS_PAGE_CHANGE: 'LOGS_PAGE_CHANGE',
    DEVICEDATA_SEARCH: 'SEARCH_DATA',
    REGISTER_REQUEST: 'DEVICE_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'DEVICE_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'DEVICE_REGISTER_FAILURE',

    LIST_REQUEST: 'DEVICE_LIST_REQUEST',
    LIST_SUCCESS: 'DEVICE_LIST_SUCCESS',
    LIST_FAILURE: 'DEVICE_LIST_FAILURE',
    LIST_SUCCESS_MANAGER: 'LIST_SUCCESS_MANAGER',

    UPDATE_REQUEST: 'DEVICE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'DEVICE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DEVICE_UPDATE_FAILURE',

    FIND_REQUEST: 'DEVICE_FIND_REQUEST',
    FIND_SUCCESS: 'DEVICE_FIND_SUCCESS',
    FIND_FAILURE: 'DEVICE_FIND_FAILURE',

    DELETE_REQUEST: 'DEVICE_DELETE_REQUEST',
    DELETE_SUCCESS: 'DEVICE_DELETE_SUCCESS',
    DELETE_FAILURE: 'DEVICE_DELETE_FAILURE',

    VISIBILITY: 'SET_VISIBILITY',

    INACTIVE: 'inactive',
    ACTIVE:'active',
    DISPOSE: 'dispose',

    SEARCH: 'DEVICES_SEARCH',
}

const MAPCSV = {
    MAPCSV_ROWCLICK: 'MAPCSV_ROWCLICK',
    MAPCSV_CLEAR: 'MAPCSV_CLEAR',
    MAPCSV_STARTLOADING: 'MAPCSV_ISLOADING_START',
    MAPCSV_ENDLOADING: 'MAPCSV_ISLOADING_END',
    MAPCSV_FIND_SUCCESS: 'MAPCSV_MAP_FIND_SUCCESS'
}

// Others
const ROLE = {
    ADMIN: 'admin',
    MANAGER: 'manager',
    SUPERVISOR: 'supervisor',
    NORMALUSER: 'normal',
}

const PATH = {
    LOGIN: '/login'
}

export const CONSTANTS = {
    CLEAR: 'CLEAR',
    TIME_IDLE: 30, // value in minute

    COMPANY,
    AUTHORIZATION,
    DASHBOARD,
    USER,
    DEVICEDATA,
    DEVICE,
    HISTORY,
    BASESTATION,
    MAPCSV,

    ROLE,
    PATH,

    DATE_FORMAT: {
        DATE_TIME: 'dd-mm-yyyy HH:MM',
        DATE_TIME2: 'dd-mm-yyyy HH:MM:ss',
        DATE_TIME3: 'dd-mm-yyyy (HH:MM)',
        DATE: 'dd-mm-yyyy',
        DATE2: 'yyyy-mm-dd',
        DATE3: 'dd/mm/yyyy',
        TIME: 'HH:MM:ss',
        ddmmyyyhhmm: "dd-mm-yyyy HH:MM"
    }
}