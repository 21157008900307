var API_ROUTE = '', MQTT_URL = ''
const dev = false
;  //this should be set using node_env, please refer to dotenv in the npm registry on hows-to

if (dev) {
    // API_ROUTE = 'http://' + window.location.hostname
    API_ROUTE = 'http://' + window.location.hostname + ':3033/api'
    MQTT_URL = 'ws://' + window.location.hostname + ':3889'
} else {
    // API_ROUTE = 'https://' + window.location.hostname 
    API_ROUTE = 'https://' + window.location.hostname + '/api'
    MQTT_URL = 'wss://' + window.location.hostname + '/mqtt'
}

export const SERVER = {
    API: {
        Login: API_ROUTE + '/AppUsers/login',
        Logout: API_ROUTE + '/AppUsers/logout',
        ChangePassword: API_ROUTE + '/AppUsers/change-password',
        ResetPassword: API_ROUTE + '/AppUsers/reset/password',
        resetPasswordByAdmin: API_ROUTE + '/AppUsers/resetpasswordbyadmin',
        resetPasswordByEmail: (email) => { return API_ROUTE + '/AppUsers/resetPasswordByEmail/' + email },

        Storage: {
            UploadUserPicture: API_ROUTE + '/Stores/PictureUser/upload',
            GetUserPicture: (fileName) => { return API_ROUTE + '/Stores/PictureUser/download/' + fileName },
            UploadDevicePicture: API_ROUTE + '/Stores/PictureDevice/upload',
            GetDevicePicture: (fileName) => { return API_ROUTE + '/Stores/PictureDevice/download/' + fileName },
        },

        Company: {
            GetAllCompanyList: API_ROUTE + '/Companies/listCompany',
            RegisterCompany: API_ROUTE + '/Companies/register',
            RemoveCompany: (id) => { return API_ROUTE + '/Companies/removeCompany/' + id},
            UpdateCompany: (id) => { return API_ROUTE + '/Companies/update/' + id },
        },

        Basestation: {
            GetAllBasestationList: API_ROUTE + '/Basestations/listBasestation',
            GetLatestBasestationMarker: API_ROUTE + '/DeviceData/getLatestBasestationData',
            RegisterBasestation: API_ROUTE + '/Basestations/register',
            UpdateBasestation: (id) => { return API_ROUTE + '/Basestations/update/' + id },
            DeleteBasestation: (id) => { return API_ROUTE + '/Basestations/removeBasestation/' + id },
        },

        AppUser: {
            MAIN: API_ROUTE + 'AppUsers',

            RegisterAdmin: API_ROUTE + '/AppUsers/register/admin',
            RegisterManager: API_ROUTE + '/AppUsers/register/manager',
            RegisterSupervisor: API_ROUTE + '/AppUsers/register/supervisor',
            RegisterNormalUser: API_ROUTE + '/AppUsers/register/normalUser',

            ListAdmin: API_ROUTE + '/AppUsers/list/admins',
            ListManager: API_ROUTE + '/AppUsers/list/managers',
            ListSupervisor: API_ROUTE + '/AppUsers/list/supervisors',
            ListNormalUser: API_ROUTE + '/AppUsers/list/normalUsers',
            AllCompanyWithBasestation: API_ROUTE + '/AppUsers/list/company/basestation',

            ChangeContact: (id) => { return API_ROUTE + '/AppUsers/contact/change/' + id },
            RemoveUser: (id) => { return API_ROUTE + '/AppUsers/remove/' + id },
            UpdateUser: (id) => { return API_ROUTE + '/AppUsers/update/' + id },
            ChangeRole: (id) => { return API_ROUTE + '/AppUsers/changeRole/' + id },
            ResetPassword: (id) => { return API_ROUTE + '/AppUsers/password/reset/' + id },

            UpdateById: (id) => { return API_ROUTE + '/AppUsers/' + id }
        },

        Device: {
            Device: API_ROUTE + '/Devices',
            Statistic: API_ROUTE + '/Devices/devicestatistic',
            Delete: API_ROUTE + '/Devices/',
            Update: API_ROUTE + '/Devices/update'
        },

        DeviceData: {
            MAIN: API_ROUTE + '/DeviceData',
            LATEST: API_ROUTE + '/DeviceData/latest',
            ListDevice: API_ROUTE + '/CollarDevices/list',
            ListDeviceManager: (uid) => {return API_ROUTE + '/CollarDevices/list/manager/' + uid},
            RegisterDevice: API_ROUTE + '/CollarDevices/register',
            HistoryData: API_ROUTE + '/DeviceData/getHistoryData',
            DataForMap: API_ROUTE + '/DeviceData/getDataForMap',
            HistoryDataByPageManager: (page) => { return API_ROUTE + '/DeviceData/getHistoryByPageManager/' + page },
            HistoryDataByPage: (page) => { return API_ROUTE + '/DeviceData/getHistoryByPage/' + page },
            LatestData: API_ROUTE + '/DeviceData/getLatestDataByDevice/',
            LatestDataManager: (uid) => {return API_ROUTE + '/DeviceData/getLatestDataByDevice/manager/' + uid},

            // CheckboxDevice: (check) => {return API_ROUTE + '/CollarDevices/checkboxDevice/' + check},
            CheckboxDevice: (role, id) => {return `${API_ROUTE}/CollarDevices/checkboxDevice/${role}/${id}`},

            RemoveDevice: (id) => { return API_ROUTE + '/CollarDevices/removeDevice/' + id },
            UpdateDevice: (id) => { return API_ROUTE + '/CollarDevices/updateDevice/' + id },
        },

        History: {
            MAIN: API_ROUTE + '/HistoryData',
            GetHistoryDataByDevice: (id) => { return API_ROUTE + '/DeviceData/getHistoryDataByDevice/' + id},
            GetHistoryDeviceByDate: API_ROUTE + '/DeviceData/getHistoryDeviceByDate',
            // Update: MAIN+'/api/History/update/info'
        },

    },
    MQTT: {
        URL: MQTT_URL,
        User: 'gpscollar_dash',
        Pass: 'asdh@453bia&2sfeeYq3rs'
    }
}
