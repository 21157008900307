import { CONSTANTS } from '../api'

let initState = {
    data: [],
    dataList: [],
    pagination: {
      itemPerPage: 10,
      currentPage: 1,
      totalPage: 1
    },
    searchValue: '',
    dataSearch: [],
}

export function historybydevice (state = initState, action) {

    let _data = []
    let __state = {}
    let _j = 0

    switch (action.type) {
        case CONSTANTS.HISTORY.CLEAR:
            __state = {}

            __state = {...state}
            
            __state = {
                data: [],
                dataList: [],
                pagination: {
                  itemPerPage: 0,
                  currentPage: 0,
                  totalPage: 0
                },
                error: null
            };

            return __state

        case CONSTANTS.HISTORY.FILTER_SUCCESS:
            __state = {}

            _data = []
            _j = 0

            console.log(action.result.length)

            for (let i = 0; i < action.result.length; i++) {
                action.result[i]['count'] = i+1
                // if (i < 10) {
                //   _data.push(action.result[i])
                //   console.log(_data)
                // }
            }

            __state = {...state}
            
            __state = {
                data: action.result,
                dataList: _data,
                pagination: {
                  itemPerPage: 10,
                  currentPage: 1,
                  totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };

            console.log(__state)

            return __state;

        case CONSTANTS.HISTORY.FIND_SUCCESS:
            _data = []

            for (let i = 0; i < action.result.length; i++) {

                if (i < 10) {
                    _data.push(action.result[i])
                }
            }

            state = {
                ...state,
                data: action.result,
                dataList: _data,
                pagination: {
                    itemPerPage: 10,
                    currentPage: 1,
                    totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };
            
            return state;

        case CONSTANTS.HISTORY.LIST_BY_DEVICE_SUCCESS:

            for (let i = 0; i < action.result.length; i++) {

                if (i < 10) {
                    _data.push(action.result[i])
                }
            }

            console.log(_data)

            state = {
                ...state,
                data: action.result,
                dataList: _data,
                pagination: {
                    itemPerPage: 10,
                    currentPage: 1,
                    totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };
            
            return state;

        case CONSTANTS.HISTORY.LOGS_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.currentPage = action.result
            console.log('LOG PAGE CHANGE => ',  __state.pagination.currentPage)
    
            __state.dataList = []
            _j = 0

            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
    
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.data[i])
                _j++
            }
            }

            state = {
                ...__state
                };

            return state;

        // case CONSTANTS.HISTORY.FILTER_SUCCESS:
        //     for (let i = 0; i < action.result.length; i++) {
        //         if (i < 10) {
        //           _data.push(action.result[i])
        //         }
        //     }

        //     state = {
        //         ...state,
        //         data: action.result,
        //         dataList: _data,
        //         pagination: {
        //           itemPerPage: 10,
        //           currentPage: 1,
        //           totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
        //         },
        //         error: null
        //     };
        //     return state;

        default:
            return state

    }
}