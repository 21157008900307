import { CONSTANTS } from '../api';
let initState = {
    data: [],
    dataList: [],
    marker: {
        id: 0,
        visibility: false,
    },
    pagination: {
      itemPerPage: 5,
      currentPage: 1,
      totalPage: 1
    },
    allDevice: false,
    searchValue: '',
    dataSearch: [],
}

export function device(state = initState, action) {
    let __state = {}
    let _j = 0
    let _data = []

    switch (action.type) {
        case CONSTANTS.DEVICE.LOGS_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.currentPage = action.result
            console.log('LOG PAGE CHANGE => ',  __state.pagination.currentPage)
    
            __state.dataList = []
            _j = 0

            for (let i = ((action.result - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
    
            if (_j < state.pagination.itemPerPage) {
                __state.dataList.push(__state.data[i])
                _j++
            }
            }

            state = {
                ...__state
                };

            return state;

        case CONSTANTS.DEVICE.LIST_SUCCESS:
            _data = []

            for (let i = 0; i < action.result.length; i++) {
                action.result[i]['count'] = i
                if (i < 5) {
                    _data.push(action.result[i])
                }
            }

            const visible = action.result.reduce((total, device) => {
                if (device.visible === true) {
                    if (total + 1 === action.result.length) {
                        return true
                    }
                    else {
                        return total + 1
                    }
                }
                return total
            },0)

            if (visible === true) {
                state = {
                    ...state,
                    allDevice: true
                }
            }
            else {
                state = {
                    ...state,
                    allDevice: false
                }
            }

            // console.log(visible, state.allDevice)

            state = {
                ...state,
                data: action.result,
                dataList: _data,
                pagination: {
                    itemPerPage: 5,
                    currentPage: 1,
                    totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };
            
            return state;

        case CONSTANTS.DEVICE.LIST_SUCCESS_MANAGER:
            // _data = []
            __state = {}

            console.log(action.result)
            
            // for (let i = 0; i < action.result.length; i++) {
            //     console.log(action.result[i].basestation === undefined )
            //     if ( action.result[i].basestation !== undefined ) {
            //         if ( action.result[i].basestation.appUserId === action.result.uid ) {
            //             filtered.push(action.result[i])
            //         }
            //     }
            // }

            // console.log(action.result.uid)

            for (let i = 0; i < action.result.length; i++) {
                action.result[i]['count'] = i
                if (i < 5) {
                    _data.push(action.result[i])
                }
            }

            const visibl = action.result.reduce((total, device) => {
                if (device.visible === true) {
                    if (total + 1 === action.result.length) {
                        return true
                    }
                    else {
                        return total + 1
                    }
                }
                return total
            },0)

            if (visibl === true) {
                state = {
                    ...state,
                    allDevice: true
                }
            }
            else {
                state = {
                    ...state,
                    allDevice: false
                }
            }

            __state = {
                ...state,
                data: action.result,
                dataList: _data,
                pagination: {
                    itemPerPage: 5,
                    currentPage: 1,
                    totalPage: Math.ceil(action.result.length / state.pagination.itemPerPage)
                },
                error: null
            };
            
            return __state;

        case CONSTANTS.DEVICE.DEVICEDATA_SEARCH:
            __state = { ...state }
            __state.dataList = []
            __state.dataSearch = []
    
            __state.pagination.currentPage = 1
            __state.searchValue = action.result
            
            if(__state.searchValue.length !== 0) {
                state.data.forEach(element => {
                    if(element.basestation !== undefined){
                        // console.log(element, typeof(action.result))
                        if ( 
                            element.id.toString().toLowerCase().includes(action.result.toLowerCase()) || 
                            element.name.toLowerCase().includes(action.result.toLowerCase()) || 
                            element.device_id.toLowerCase().includes(action.result.toLowerCase()) || 
                            element.basestation.basestation_id.toLowerCase().includes(action.result.toLowerCase()) ||
                            element.basestation.company.companyName.toLowerCase().includes(action.result.toLowerCase()) || 
                            element.description.toLowerCase().includes(action.result.toLowerCase()) ) {
                            __state.dataSearch.push(element)
                        }
                    }
                })

                __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)

                _j = 0

                for (let i = 0; i < __state.dataSearch.length; i++) {
                    if (_j < state.pagination.itemPerPage) {
                        __state.dataList.push(__state.dataSearch[i])
                        _j++
                    }
                }

                state = {...__state}
            }
            else {
                // console.log(state)
                _data = []

                for (let i = 0; i < action.result.length; i++) {
                    action.result[i]['count'] = i
                    if (i < 5) {
                        _data.push(action.result[i])
                    }
                }
            }

            return state

        case CONSTANTS.DEVICE.VISIBILITY:
            __state = {...state}
            __state.marker.id = action.result.id
            __state.marker.visibility = action.result.visibility
            
            return __state

        case CONSTANTS.CLEAR:
            return initState

        default:
            return state
    }

}